import React from 'react';

const MaintenancePage = () => {
  return (
    <div style={styles.body}>
      <div style={styles.container}>
        <h1 style={styles.heading}>We'll be back soon!</h1>
        <p style={styles.text}>
          Our site is currently undergoing scheduled maintenance. We apologize for any inconvenience.
        </p>
        <p style={styles.text}>Thank you for your patience.</p>
      </div>
    </div>
  );
};

const styles = {
  body: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: 'green',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
  },
  container: {
    maxWidth: '600px',
    padding: '20px',
    background: '#fff',
    boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
    borderRadius: '10px',
  },
  heading: {
    color: '#333',
  },
  text: {
    color: '#666',
  },
};

export default MaintenancePage;
