import React, { useEffect, useState } from 'react';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import io from 'socket.io-client';
import './BookList.css';

const socket = io('https://www.panafricanbookexchange-wandererspath.com'); // Replace with your Socket.io server URL

const BookGraph = ({ book }) => {
  const [priceHistory, setPriceHistory] = useState([]);
  const [labels, setLabels] = useState([]);

  useEffect(() => {
    setPriceHistory([book.price]);
    setLabels([new Date().toLocaleTimeString()]);

    socket.on('priceUpdate', (newPrice) => {
      setPriceHistory((prevHistory) => [...prevHistory, newPrice]);
      setLabels((prevLabels) => [...prevLabels, new Date().toLocaleTimeString()]);
    });

    return () => {
      setPriceHistory([]);
      setLabels([]);
      socket.off('priceUpdate');
    };
  }, [book]);

  const data = {
    labels: labels.length ? labels : ['Initial'],
    datasets: [
      {
        label: 'Price (Fanon)',
        data: priceHistory.length ? priceHistory : [book.price],
        fill: false,
        backgroundColor: 'rgba(255,99,132,0.2)',
        borderColor: 'rgba(255,99,132,1)',
        borderWidth: 2,
        tension: 0.4,
        pointRadius: 5,
        pointHoverRadius: 7,
        pointBackgroundColor: 'rgba(255,99,132,1)',
      },
    ],
  };

  const options = {
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: 'Time',
        },
      },
      y: {
        beginAtZero: false,
        title: {
          display: true,
          text: 'Price (Fanon)',
        },
        ticks: {
          callback: function (value) {
            return `${value.toLocaleString()} Fanon`;
          },
        },
      },
    },
    plugins: {
      legend: {
        display: true,
        position: 'top',
      },
      tooltip: {
        enabled: false, // Disable default tooltip
        external: function (context) {
          const tooltipModel = context.tooltip;
          let tooltipEl = document.getElementById('custom-tooltip');

          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'custom-tooltip';
            tooltipEl.className = 'custom-tooltip';
            document.body.appendChild(tooltipEl);
          }

          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          const priceIndex = tooltipModel.dataPoints?.[0]?.dataIndex;
          if (priceIndex !== undefined) {
            const price = priceHistory[priceIndex];
            const time = labels[priceIndex];
            tooltipEl.innerHTML = `<strong>${price} Fanon</strong><br>${time}`;
          }

          tooltipEl.style.opacity = 1;
          tooltipEl.style.left = `${tooltipModel.caretX + 15}px`;
          tooltipEl.style.top = `${tooltipModel.caretY + window.scrollY - 30}px`;
        },
      },
    },
    animation: {
      duration: 0,
    },
    responsive: true,
    maintainAspectRatio: false,
  };

  return (
    <div className="book-graph" style={{ height: '300px', position: 'relative' }}>
      <Line data={data} options={options} />
      <div id="custom-tooltip" className="custom-tooltip"></div>
    </div>
  );
};

export default BookGraph;
